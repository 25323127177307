var menu = document.querySelector('.navbar-toggler');
menu.addEventListener('click', function() {
    document.body.classList.toggle('overlay');
});

Modernizr.on('webp', function (result) {
    if (result) {
        document.body.classList.add('webp');
    } else {
        document.body.classList.add('nowebp');
    }
});

jQuery(function() {
    jQuery('a.scroll').on('click', function(e) {
        e.preventDefault();
        if (document.body.classList.contains('overlay'))
            jQuery('.navbar-toggler').click();
        var GetaName = jQuery(this).attr('href').split('#');
        var target = jQuery('a[name ='+GetaName[1]+']');
        if (target.offset()) {
            jQuery('html,body').animate({ scrollTop: target.offset().top }, 500);
        } else {
            window.location.href = jQuery(this).attr('href');
        }
    });
});